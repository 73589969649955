.header {
  top: 0;
  left: 0;
  width: 100%;
  background-color: #24262b;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  padding: 0;
  margin: 0;
}

.header > a {
  text-decoration: none;
}

.home-button {
  display: flex;
  transition: transform 0.075s linear;
}

.home-button > h2 {
  margin: 0;
  padding: 10px 30px;
}

.navbar-icon {
  margin: auto 10px;
}

.navbar-title {
  user-select: none;
  color: white;
  margin: 0;
}

.header ul li {
  display: inline-block;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  transition: all 0.25s ease 0s;
  text-decoration: none;
  color: white;
  display: block;
  padding: 16px;
  margin: 0;
}

.active-page {
  background-color: black;
}

/* Burger Menu */

.burger-btn {
  cursor: pointer;
  display: none;
  padding: 16px;
}

#burger-checkbox {
  display: none;
}

#burger-x-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .burger-btn {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 0;
    background-color: #24262b;
    z-index: 10;
    width: 50%;
    min-width: 300px;
    right: -100%;
    height: 100vh;
    text-align: center;
    transition: all 0.5s;
  }

  .show-side-bar {
    right: 0%;
  }

  .nav-links ul li {
    display: block;
  }

  #burger-x-btn {
    display: block;
    cursor: pointer;
  }

  .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    animation: fadein 0.5s forwards;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (any-hover: hover) and (pointer: fine) {
  .home-button:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  .nav-links li a:hover,
  .burger-btn:hover,
  #burger-x-btn:hover {
    background-color: white;
    color: black;
  }
}
