.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
}

.carousel-container {
  max-width: 40rem;
  flex: 4;
  margin: 0.5rem;
}

.info-container {
  min-width: 15rem;
  flex: 1;
  margin: 0.5rem;
}

.title {
  font-size: 2rem;
}

.info-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desc > * {
  margin: 0;
  padding: 0;
}

.btn-container {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.btn-container button {
  width: 100%;
  max-width: 350px;
}

@media screen and (max-width: 1000px) {
  .carousel-container {
    max-width: none;
    width: 100%;
    flex: none;
  }

  .info-container {
    width: 100%;
    flex: none;
  }
}
