.button {
  background-color: rgb(205, 80, 90);
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  padding: 0.8rem 2.6rem;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease 0s;
}

.secondary {
  background-color: white;
  color: black;
  box-sizing: border-box;
  border: solid 2px black;
}

.pill {
  border-radius: 50rem;
}

.disabled {
  border: 2px solid #cccccc;
  background-color: rgb(229, 229, 229);
  color: #8d8d8d;
  pointer-events: none;
  user-select: none;
}


@media (any-hover: hover) and (pointer: fine) {
  .button:hover {
    background-color: rgb(137, 53, 60);
  }
  .secondary:hover {
    background-color: #333;
    color: white;
  }
}
