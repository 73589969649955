.title {
    margin: 0;
    font-size: 1.25rem;
}

.desc {
    margin: 0;
    font-size: 1rem;
    font-style: italic; 
}

.optional-text {
    color: #999;
    font-size: 1rem;
}

.item-container {
    display: flex;
    gap: 16px;
    padding: 16px 0;
    width: 100%;
}

.text-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.desc-container {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .optional-text {
      display: none;
    }
  }