.container {
  width: 100%;
  padding-bottom: 75%;
  background-color: lightgray;
  position: relative;
  overflow: hidden;
}

.container > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slide {
  position: absolute;
  inset: 0;
  opacity: 1;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide > h1 {
  user-select: none;
}

.slide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.btn {
  position: absolute;
  border: none;
  font-size: 4rem;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.25);
  user-select: none;
  padding: 1rem;
}

.btn.prev {
  left: 0;
}

.btn.next {
  right: 0;
}

@media (any-hover: hover) and (pointer: fine) {
  .btn:hover,
  .btn:focus {
    color: white;
  }
}
