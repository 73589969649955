:root {
  --color-hero-bg: rgb(33, 33, 49);
  --color-hero-bg-trans: rgba(33, 33, 49, 0.5);
}
.container {
  background-color: var(--color-hero-bg);
  position: relative;
  overflow: hidden;
}

.main-content {
  text-align: center;
  position: relative;
  color: white;
  padding: 4rem 0;
  pointer-events: none;
}

.picture {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.picture-container {
  border-radius: 50%;
  background-color: white;
  width: 275px;
  height: 275px;
  display: block;
  margin: auto;
  overflow: hidden;
  padding: 0.5rem;
}

.title {
  font-size: 3rem;
  margin: 1rem auto;
  background-color: var(--color-hero-bg-trans);
  display: table;
  padding: 0 1rem;
}

.description {
  font-size: 1.5rem;
  background-color: var(--color-hero-bg-trans);
  margin: auto;
  display: table;
  padding: 0 1rem;
}

/* Hero Background Animation */

.bg-content {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .text-container {
    background-color: var(--color-hero-bg-trans);
  }
  .title, .description {
    background-color: transparent;
  }
}