@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

:root {
  --sm-screen: 575px;
  --lg-screen: 1000px;
}

* {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}

body {
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
}

.bg-grey {
  background-color: rgb(248, 249, 250);
}

.bg-white {
  background-color: white;
}

.section-content {
  text-align: center;
  max-width: 65rem;
  width: 85%;
  margin: auto;
  position: relative;
  padding: 1rem;
}

section {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.section-title {
  font-size: 2rem;
  margin: 0;
  text-align: left;
}