:root {
  --footer-height: 7rem;
}

.footer {
  background-color: #24262b;
  color: #ffffff;
  padding-bottom: .5rem;
  padding-top: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  height: var(--footer-height);
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.footer p {
  font-size: .75rem;
}

.icons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icons-container > a {
  text-decoration: none;
  color: white;
  transition: all 0.1s linear 0s;
  margin: 0 0.5rem;
}


@media (any-hover: hover) and (pointer: fine) {
  .icons-container > a:hover {
    transform: scale(1.25);
  }  
}