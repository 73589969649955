.container {
  padding: 1rem;
  width: 19rem;
}

.card {
  height: 100%;
}

.card > * {
  margin: 0;
  padding: 0;
}

.card > h1 {
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
}

.card > p {
  line-height: 1.4rem;
}

.link {
  text-decoration: none;
  color: black;
}

.img-container > img {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 0.25rem;
  pointer-events: none;
}

.img-container {
  position: relative;
}

.badge {
  background-color: rgb(104, 228, 104);
  color: white;
  position: absolute;
  bottom: 10px;
  right: 0;
  margin: 0.5rem;
  padding: 0.25rem 0.4rem;
  font-size: 0.9rem;
  border-radius: 0.5rem;
}

@media (any-hover: hover) and (pointer: fine) {
  .card:hover {
    transform: scale(1.025);
    cursor: pointer;
  }
}
