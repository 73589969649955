.doodles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.doodles > div {
  position: absolute;
  display: block;
}

.doodles > div > img {
  width: 150%;
}


.doodles > div:nth-child(1) {
  left: 20%;
  animation: animate 15s linear infinite;
}

.doodles > div:nth-child(2) {
  left: 50%;
  animation: animate 12s linear infinite;
  animation-delay: -5s;
}

.doodles > div:nth-child(3) {
  left: 70%;
  animation: animate 22s linear infinite;
  animation-delay: 0s;
}

.doodles > div:nth-child(4) {
  left: 0%;
  animation: animate 16s linear infinite;
  animation-delay: -5s;
}

.doodles > div:nth-child(5) {
  left: 85%;
  animation: animate 18s linear infinite;
  animation-delay: -7s;
}


@keyframes animate {
  0% {
    opacity: 0;
    top: -10%;
    transform: translate(20px) rotate(0deg);
  }

  10% {
    opacity: 1;
  }

  20% {
    transform: translate(20px) rotate(45deg);
  }

  40% {
    transform: translate(-20px) rotate(90deg);
  }

  60% {
    transform: translate(20px) rotate(180deg);
  }

  80% {
    transform: translate(-20px) rotate(180deg);
  }

  100% {
    top: 110%;
    transform: translate(-20px) rotate(225deg);
  }
}
